.main-form {
 padding: 5% 10% 10% 10%;
}


.form-switch .form-check-input {
 margin-left: 0;
}


.form-check .form-check-input {
 margin-left: 0;
}


.form-check   {
 padding: 16px 0;
}


.check-again {
margin-bottom: 18px;
}


.mb-3 {
margin: 2.5% 0;
}


input {
height: 47px;
}


textarea {
height: 15em;
}


.form-check-input {
width: 4em;
}


.add {
color: #0d6efd;
background-color: #9ef89f;

--bs-btn-hover-color:#0d6efd;
--bs-btn-hover-bg: #9df5db;
--bs-btn-hover-border-color: #0d6efd;

--bs-btn-active-color: #0d6efd;
--bs-btn-active-bg: #56fced;
--bs-btn-active-border-color: #0d6efd;
}