.offcanvas-title {
    color: #3fd020;
}


.form-check {
    padding: 16px 12px;
}


.form-check-input1 {
    margin-right: 1.5em;
    height: 1.2em;
    width: 1.2em;
}


.sign-in {
    color : #3fd020;
    --bs-btn-border-color:#3fd020;

    --bs-btn-hover-color: #309b19;
    --bs-btn-hover-bg: #d8ff63;
    --bs-btn-hover-border-color: #3fd020;

    --bs-btn-active-color: #0d6efd;
    --bs-btn-active-bg: #a4fa87;
    --bs-btn-active-border-color: #0d6efd;
}


.reg {
    --bs-btn-hover-color:#0d6efd;
    --bs-btn-hover-bg: #9df5db;
    --bs-btn-hover-border-color: #0d6efd;

    --bs-btn-active-color: #0d6efd;
    --bs-btn-active-bg: #56fced;
    --bs-btn-active-border-color: #0d6efd;
}



.register {
    padding: 65px 12px ;
}


.sign-out {
    color : orange;
    --bs-btn-border-color:orange;

    --bs-btn-hover-color: orange;
    --bs-btn-hover-bg: #fdfbad;
    --bs-btn-hover-border-color: orange;

    --bs-btn-active-color: #05ea08;
    --bs-btn-active-bg: #fffb50;
    --bs-btn-active-border-color: #f5ef7d;
}



.greetings {
    margin-bottom: 1.5em;
    font-size: 20px;
}