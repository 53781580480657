body {
 background:#fffbad;
}


.detal {
    --bs-btn-hover-color:#0d6efd;
    --bs-btn-hover-bg: #9df5db;
    --bs-btn-hover-border-color: #0d6efd;

    --bs-btn-active-color: #0d6efd;
    --bs-btn-active-bg: #56fced;
    --bs-btn-active-border-color: #0d6efd;
}


.name {
    color: rgb(255 163 1);
    padding-bottom: 3px;
}


.titels {
    color: #40b6af;
}


.details {
    color: rgb(137 93 17);
}


.sight-card {
    margin: 5% 2%;
    padding:2.5%;
    border-radius: 50px;
    background-color: #fffb4f;
    border: 4px solid #6efafa;
}


.sight-card-info {
   height: auto;
}


i.favorite {
    font-size: 35px;
    color: white;
    -webkit-text-stroke: 2px #3bcbcb;
}


i.favorite.active {
    color: #fffb4f;
}


.scroll-content {
    margin: 1%;
    height: 491px;
}


@media(min-width: 576px) {
    .scroll-content {
        height: 491px;
    }
    .detal {
        width: 5em;

    }
}


@media(min-width: 768px) {
    .scroll-content {
        height: 491px;
    }
    .detal {
        width: 11em;
    }
}