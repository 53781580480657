.modal-body {
    color: rgb(105, 69, 10);
}


.deletion {
    color : orange;
    --bs-btn-border-color:orange;

    --bs-btn-hover-color: orange;
    --bs-btn-hover-bg: #fdfbad;
    --bs-btn-hover-border-color: orange;

    --bs-btn-active-color: #05ea08;
    --bs-btn-active-bg: #fffb50;
    --bs-btn-active-border-color: #f5ef7d;
}


.back {
    color : #3fd020;
    --bs-btn-border-color:#3fd020;

    --bs-btn-hover-color: #309b19;
    --bs-btn-hover-bg: #d8ff63;
    --bs-btn-hover-border-color: #3fd020;

    --bs-btn-active-color: #0d6efd;
    --bs-btn-active-bg: #a4fa87;
    --bs-btn-active-border-color: #0d6efd;
}